'use client'

import Link from 'next/link'

import { buttonVariants } from '@/clientComponents/ui/Button/Button'
import { Card, CardContent, CardDescription, CardHeader, CardTitle } from '@/clientComponents/ui/Card/Card'

export default function Error({}: { error: Error & { digest?: string }; reset: () => void }) {
  return (
    <Card className="max-w-2xl mx-auto mt-8">
      <CardHeader>
        <CardTitle>Something went wrong!</CardTitle>
        <CardDescription>
          We seem to be running into a bit of an issue. Try again by pressing the button below, or reach out to our
          customer success team.
        </CardDescription>
      </CardHeader>
      <CardContent>
        <Link href={`/api/auth/silent?returnTo=${window.location.href}`} className={buttonVariants()}>
          Try Again
        </Link>
      </CardContent>
    </Card>
  )
}
